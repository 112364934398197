body {
  padding: 0;
  margin: 0;
  background-color: #fff;
  font-family: "Calibri", Helvetica, Arial, sans-serif;
}

#gm_mock h1 {
  margin: 0;
  //padding: 0.25em; removed because of overwrite
  line-height: 1em;
}

#map {
  //position: absolute;
  width: 800px;
  min-height: 400px;
  margin-top: 2em;
  margin-left: 2em;
  border: 5px solid #ccc;
}

.fieldLabel {
  min-width: 100px;
  display: table-cell;
}
.fieldInput {
  min-width: 120px;
  display: table-cell;
}

div{
    font-family: "Calibri", Helvetica, Arial, sans-serif;
}
